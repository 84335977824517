import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import useScript from "./components/utils/useScript";
import "./components/assets/css/font.css";
// import "../components/assets/css/customstyle.css";
// import "./components/assets/css/adminlte.min.css";
// import "./components/assets/font-awesome-5.13.0/css/all.css";
import "./index.css";
import "./App.css";
import Deals from "./components/Deals";
import Sidebar from "./layout/header";
import logoimg from "./components/assets/img/logo_sm.png";
import logoimg1 from "./components/assets/img/logo.png";
import Blog from "./components/Blog";
// import Prodcuts from "./components/Products";
import Products from "./components/Products";
import Coupon from "./components/coupon";
import Category from "./components/Category";
export default function App() {
  useScript("https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js");

  useEffect(() => {
    const spinner = document.getElementById("spinner");

    if (spinner && !spinner.hasAttribute("hidden")) {
      spinner.setAttribute("hidden", "true");
    }
  }, []);
  const logoutuser = (e) => {
    sessionStorage.setItem("email", "");
    sessionStorage.setItem("user_id", "");
    window.location.reload(true);
  };

  let blogIsActive = "nav-link";
  let dealsIsActive = "nav-link";
  let productsIsActive = "nav-link";
  let couponIsActive = "nav-link";
  let catgoryIsActive = "nav-link";
  let page = "Deals";
  if (window.location.pathname.includes("Deals")) {
    dealsIsActive += " active";
    page = "Deals";
  } else if (window.location.pathname.includes("Blog")) {
    blogIsActive += " active";
    page = "Blog";
  } else if (window.location.pathname.includes("Products")) {
    productsIsActive += " active";
    page = "Products";
  } else if (window.location.pathname.includes("Coupon")) {
    couponIsActive += " active";
    page = "Coupon";
  } else if (window.location.pathname.includes("Category")) {
    catgoryIsActive += " active";
    page = "Category";
  } else {
    dealsIsActive += " active";
  }

  return (
    <>
      <Router>
        <div className="wrapper">
          {/* Navbar */}
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="pushmenu"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-bars" />
                </a>
              </li>
              {/* <li class="nav-item d-none d-sm-inline-block">
  <a href="index3.html" class="nav-link">Home</a>
</li>
<li class="nav-item d-none d-sm-inline-block">
  <a href="#" class="nav-link">Contact</a>
</li> */}
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a onClick={logoutuser} role="button">
                  <span className="btn btn-primary btn-sm">
                    <i className="fas fa-sign-out-alt mr-2" />
                    Logout
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          {/* /.navbar */}
          {/* Main Sidebar Container */}
          <aside className="main-sidebar  sidebar-light-primary elevation-4">
            {/* Brand Logo */}
            <a href="#" className="brand-link logo-switch">
              <img
                src={logoimg}
                alt="Hellalit Weedfinder"
                className="brand-image-xl logo-xs"
              />
              <img
                src={logoimg1}
                alt="Hellalit Weedfinder"
                className="brand-image-xs logo-xl"
                style={{ left: 45 }}
              />
            </a>
            {/* Sidebar */}
            <div className="sidebar">
              {/* Sidebar Menu */}
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                  <li className="nav-item">
                    <a href="/Deals" className={dealsIsActive}>
                      <i className="far fa-circle nav-icon icon_posrel" />
                      <p>Deals</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/Blog" className={blogIsActive}>
                      <i className="far fa-circle nav-icon icon_posrel" />
                      <p>Blog</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/Category" className={catgoryIsActive}>
                      <i className="far fa-circle nav-icon icon_posrel" />
                      <p>Category</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/Products" className={productsIsActive}>
                      <i className="far fa-circle nav-icon icon_posrel" />
                      <p>Products</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/Coupon" className={couponIsActive}>
                      <i className="far fa-circle nav-icon icon_posrel" />
                      <p>Coupon</p>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
          </aside>
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0"> {page}</h1>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        {page === "Deals" ? (
                          <Deals />
                        ) : page === "Blog" ? (
                          <Blog />
                        ) : page === "Products" ? (
                          <Products />
                        ) : page === "Coupon" ? (
                          <Coupon />
                        ) : page === "Category" ? (
                          <Category />
                        ) : (
                          <Deals />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* /.col-md-6 */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            {/* /.content */}
          </div>
        </div>
        <Switch>
          <Route path="/" element={<Deals />} />
          <Route path="/Lists" element={<Deals />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Products" element={<Products />} />
        </Switch>
        <Route />
      </Router>
    </>
  );
}
