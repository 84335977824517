import React from "react";
import { format } from "date-fns";

const TimestampDisplay = ({ isoTimestamp }) => {
  console.log("isoTimestamp:", isoTimestamp);
  let formattedDate = "Invalid Timestamp"; // Default value for error handling

  try {
    const dateObject = new Date(isoTimestamp);
    formattedDate = format(dateObject, "MMMM d, yyyy HH:mm:ss");
  } catch (error) {
    console.error("Error parsing timestamp:", error);
  }

  return <div>{formattedDate}</div>;
};

export default TimestampDisplay;
