/* eslint-disable array-callback-return */
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FormControl } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { slug } from 'github-slugger';
const Category = () => {
  const [categorylist, setCategoryList] = useState([]);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState([]);

  const [name, setCategoryname] = useState("");
  const [description, setDescription] = useState("");
  const [category_id, setCategoryId] = useState("");




  const handleClose = () => setShow(false);

  const [validated, setValidated] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });
  const [edit, setEdit] = useState(false);
  const [Category_id, setCategoryid] = useState("");
  const [productdelete, setProductdelete] = useState(false);
  const [category_status, setCategoryStatus] = useState(false);
  const [delete_Category_id, setDeleteCategoryid] = useState();
  const handleClose1 = () => setProductdelete(false);
  const handleeditClose = () => setEdit(false);



  const getCategories = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "categories/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCategoryList(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {

    getCategories();
  }, []);
  const handleChangename = (e) => {
    setCategoryname(e.target.value);
    console.log(e.target.value);
  };
  const handleChangedescription = (e) => {
    setDescription(e.target.value);
    console.log(e.target.value);
  };




  const handlesubmit = (e) => {
    setValidated(true);

    var user_id = sessionStorage.getItem("user_id");

    if (name) {
      console.log("category_id", category_id);
      const file_detailsd = new FormData();
      file_detailsd.append("name", name);
      file_detailsd.append("slug", name);

      file_detailsd.append(
        "description",
        description
      );


      axios
        .post(process.env.REACT_APP_API_URL + "categories/create", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1"); toast.success(res.data.message);
              setShow(false);
              getCategories();

              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteCategoryConfirm = (id, category_status) => {
    console.log("delete id", id);
    if (id) {
      setCategoryStatus(category_status);
      setProductdelete(true);
      setDeleteCategoryid(id, category_status);
    }
  };
  const deleteCategory = () => {
    console.log("delete id", delete_Category_id);
    if (delete_Category_id) {
      const file_detailsd = new FormData();
      file_detailsd.append("category_id", delete_Category_id);
      // file_detailsd.append("category_status", category_status);
      axios
        .post(process.env.REACT_APP_API_URL + "categories/delete", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setProductdelete(false);
              getCategories();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleeditsubmit = (event) => {
    event.preventDefault();

    var user_id = sessionStorage.getItem("user_id");
    if (name && description) {
      const file_detailsd = new FormData();
      file_detailsd.append("name", name);
      file_detailsd.append("slug", name);

      file_detailsd.append(
        "description",
        description
      );
      file_detailsd.append(
        "category_id",
        Category_id
      );


      axios
        .post(process.env.REACT_APP_API_URL + "categories/update", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setEdit(false);
              getCategories();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Do something with the form data
  };
  const getCategoryInfo = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "categories/list?categories_id=" + id)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCategoryname(res.data.result.name)
          setDescription(res.data.result.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editCategory = (id) => {
    // setValidated(true);
    console.log("edit id", id);
    getCategoryInfo(id);
    setCategoryid(id);
    setEdit(true);

  };
  return (
    <div className="mrb-5">
      <Card style={{ width: "80rem" }}>
        <Card.Body>
          {/* <Card.Title>Category</Card.Title> */}
          <Button
            variant="success"
            onClick={() => {
              console.log("fff");

              setShow(true);
            }}
          >
            ADD
          </Button>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {categorylist && categorylist.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>

                    <td>{item.description}</td>
                    <td>
                      {/* <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editCategory(item._id);
                        }}
                      >
                        Edit
                      </Button> */}
                      <Button
                        className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editCategory(item._id);
                        }}
                      >
                        Edit
                      </Button>
                      {/* <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          deleteCategoryConfirm(item._id, item.status === 'inactive' ? "active" : "inactive");
                        }}
                      >
                        {item.status == 'active' ? 'Disable' : 'Enable'}
                      </Button> */}
                      <Button className="btn-sm mr-2"
                        variant="danger"
                        onClick={() => {
                          deleteCategoryConfirm(item._id, "deleted");
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Category Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
              onChange={handleChangename}
            >
              <Form.Label>Category Name</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="description"
              onChange={handleChangedescription}
            >
              <Form.Label>Description</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={edit} onHide={handleeditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Category Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
            >
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                placeholder=""
                required
                value={name}
                onChange={handleChangename}
              />
            </Form.Group>


            <Form.Group
              className="mb-3"
              controlId="description"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder=""
                required
                value={description}
                onChange={handleChangedescription}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleeditsubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={productdelete} onHide={handleClose1}>
        <Modal.Body>
          <Form>Are you sure you want to delete this category?</Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteCategory}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Category;
