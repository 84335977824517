/* eslint-disable array-callback-return */
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FormControl } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { slug } from 'github-slugger';
const Products = () => {
  const [productslist, setProductsList] = useState([]);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState([]);
  const [city_name, setCityname] = useState("");
  const [file_url, setFile] = useState("");
  const [name, setProductname] = useState("");
  const [description, setDescription] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [price, setPrice] = useState("");





  const handleClose = () => setShow(false);
  const [field, setField] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState(false);
  const [pwd, setPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const phonePattern = /^\d{10}$/;
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });
  const [edit, setEdit] = useState(false);
  const [Products_id, setProductsid] = useState("");
  const [productdelete, setProductdelete] = useState(false);
  const [products_status, setProductsStatus] = useState(false);
  const [delete_Products_id, setDeleteProductsid] = useState();
  const handleClose1 = () => setProductdelete(false);
  const handleeditClose = () => setEdit(false);
  const getProducts = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "admin/products/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setProductsList(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.localStorage.setItem("create", "none");
    // if (!Products.length)
    getProducts();
    var item_value = sessionStorage.getItem("email");
    if (!item_value) {
      setLogin(true);
    }
  }, []);

  const getCategories = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "categories/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCategory(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {

    getCategories();
  }, []);
  const handleChangename = (e) => {
    setProductname(e.target.value);
    console.log(e.target.value);
  };
  const handleChangedescription = (e) => {
    setDescription(e.target.value);
    console.log(e.target.value);
  };

  // const handleChangephone = (e) => {
  //   const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   const limitedInput = input.substring(0, 10);
  //   setPhone(limitedInput);
  //   console.log(limitedInput);
  // };
  const handleChange = (e) => {
    setCategoryId(e.target.value);
    console.log(e.target.value);
  };
  const handleChangeprice = (e) => {
    setPrice(e.target.value);
    console.log(e.target.value);
  };

  const handleChangefile = (e) => {
    console.log(e.target.files);
    const file_detailsd = new FormData();
    file_detailsd.append("file", e.target.files[0]);
    file_detailsd.append("reference", "Products");

    axios
      .post(process.env.REACT_APP_API_URL + "file/upload", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == "success") {
          // console.log(res);
          if (res.data.status === "success") {
            console.log("suceess", res.data.result.files[0].file_url);
            setFile(res.data.result.files[0].file_url);
            window.localStorage.setItem("create", "none");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectChange = (option) => {
    console.log("option", option);
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
    console.log("selectedOptions", selectedOptions);
  };
  const handlesubmit = (e) => {
    setValidated(true);
    console.log("tst");
    const value = [];
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        value.push(selectedOptions[i]);
      }
    }
    var user_id = sessionStorage.getItem("user_id");
    console.log("value", value);
    if (name && file_url && category_id) {
      console.log("category_id", category_id);
      const file_detailsd = new FormData();
      file_detailsd.append("title", name);
      file_detailsd.append("logo", file_url);
      file_detailsd.append("url", file_url);
      file_detailsd.append("price", price * 100);
      file_detailsd.append(
        "description",
        description
      );
      file_detailsd.append(
        "category_id",
        category_id
      );
      file_detailsd.append("user_id", user_id);

      axios
        .post(process.env.REACT_APP_API_URL + "Products/create", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1"); toast.success(res.data.message);
              setShow(false);
              getProducts();

              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteProductsConfirm = (id, products_status) => {
    console.log("delete id", id);
    if (id) {
      setProductsStatus(products_status);
      setProductdelete(true);
      setDeleteProductsid(id, products_status);
    }
  };
  const deleteProducts = () => {
    console.log("delete id", delete_Products_id);
    if (delete_Products_id) {
      const file_detailsd = new FormData();
      file_detailsd.append("products", delete_Products_id);
      file_detailsd.append("products_status", products_status);
      axios
        .post(process.env.REACT_APP_API_URL + "products/delete", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setProductdelete(false);
              getProducts();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleeditsubmit = (event) => {
    event.preventDefault();

    var user_id = sessionStorage.getItem("user_id");
    if (name && description) {
      const file_detailsd = new FormData();
      file_detailsd.append("name", name);
      file_detailsd.append("logo", file_url);
      file_detailsd.append("url", file_url);
      file_detailsd.append("price", price * 100);
      file_detailsd.append(
        "description",
        description
      );
      file_detailsd.append(
        "category_id",
        category_id
      );
      file_detailsd.append("product_id", Products_id);

      axios
        .post(process.env.REACT_APP_API_URL + "products/update", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setEdit(false);
              getProducts();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Do something with the form data
  };
  const getProductsInfo = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "products/list?products_id=" + id)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setProductname(res.data.result.name)
          setPrice(res.data.result.price / 100)
          setDescription(res.data.result.description);
          setCategoryId(res.data.result.category_id._id);
          setFile(res.data.result.logo);
          console.log("formValues 1", formValues);
          console.log("description 1", res.data.result.name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editProducts = (id) => {
    // setValidated(true);
    console.log("edit id", id);
    getProductsInfo(id);
    setProductsid(id);
    setEdit(true);

  };
  return (
    <div className="mrb-5">
      <Card style={{ width: "80rem" }}>
        <Card.Body>
          {/* <Card.Title>Products</Card.Title> */}
          <Button
            variant="success"
            onClick={() => {
              console.log("fff");

              setShow(true);
            }}
          >
            ADD
          </Button>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Image</th>
                <th>Catgory</th>
                <th>Price</th>
                <th>User</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {console.log("Products", Products)}
              {productslist && productslist.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <img src={item.logo} alt="Logo" width="50" height="50" />
                    </td>
                    <td>{item.category_id.name}</td>
                    <td>{"$" + item.price / 100}</td>
                    <td>{item.user_id ? item.user_id.first_name : ""}</td>
                    <td>
                      {/* <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editProducts(item._id);
                        }}
                      >
                        Edit
                      </Button> */}
                      <Button
                        className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editProducts(item._id);
                        }}
                      >
                        Edit
                      </Button>
                      <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          deleteProductsConfirm(item._id, item.status === 'inactive' ? "active" : "inactive");
                        }}
                      >
                        {item.status == 'active' ? 'Disable' : 'Enable'}
                      </Button>
                      <Button className="btn-sm mr-2"
                        variant="danger"
                        onClick={() => {
                          deleteProductsConfirm(item._id, "deleted");
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Products Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
              onChange={handleChangename}
            >
              <Form.Label>Product Name</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>
            <Form.Group
              controlId="formFile"
              onChange={handleChangefile}
              className="mb-3"
            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" required />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState" className="mb-3">
              <Form.Label>category</Form.Label>
              <Form.Select onChange={handleChange} defaultValue="Choose...">
                <option value=""> Select value </option>;
                {category.map((item) => {
                  return <option value={item._id}> {item.name} </option>;
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="price"
              onChange={handleChangeprice}
            >
              <Form.Label>Price</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="description"
              onChange={handleChangedescription}
            >
              <Form.Label>Description</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>

            {/* <Form.Group controlId="formMultiSelect" className="multiselmaxhgt">
              <Form.Label>Other cities</Form.Label>
              <Form.Control as="div">
                {city.map((item) => {
                  return (
                    <Form.Check
                      key={item._id}
                      type="checkbox"
                      id={item._id}
                      label={item.city_name}
                      value={item._id}
                      checked={selectedOptions.includes(item._id)}
                      // onChange={handleSelectChange}
                      onChange={() => handleSelectChange(item._id)}
                    />
                  );
                })}
              </Form.Control>
            </Form.Group> */}

            {/* <Form.Group controlId="formMultiSelect">
            <Form.Label> Other cities</Form.Label>
            <Form.Control as="select" multiple value={selectedOptions} onChange={handleSelectChange}>
              {city.map(item => {
                return (
                  <option value={item._id}> {item.city_name} </option>
                )
              })}
            </Form.Control>
          </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={edit} onHide={handleeditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Products Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
            >
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                placeholder=""
                required
                value={name}
                onChange={handleChangename}
              />
            </Form.Group>
            <Form.Group
              controlId="formFile"
              onChange={handleChangefile}
              className="mb-3"
            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" required />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridState" className="mb-3">
              <Form.Label>category</Form.Label>
              <Form.Select
                onChange={handleChange}
                value={category_id}
              >
                <option value="">Select value</option>
                {category.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="price"
            >
              <Form.Label>Price</Form.Label>
              <Form.Control
                placeholder=""
                required
                value={price}
                onChange={handleChangeprice}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="description"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder=""
                required
                value={description}
                onChange={handleChangedescription}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleeditsubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={productdelete} onHide={handleClose1}>
        <Modal.Body>
          <Form>Are you sure you want to {products_status === 'deleted' ? 'delete' : products_status} this product?</Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteProducts}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Products;
