/* eslint-disable array-callback-return */
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FormControl } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [show, setShow] = useState(false);
  const [city, setCity] = useState([]);
  const [city_name, setCityname] = useState("");
  const [file_url, setFile] = useState("");
  const [name, setDealname] = useState("");
  const [phone, setPhone] = useState("");
  const handleClose = () => setShow(false);
  const [field, setField] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState(false);
  const [pwd, setPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const phonePattern = /^\d{10}$/;
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });
  const [edit, setEdit] = useState(false);
  const [deals_id, setDealsid] = useState("");
  const [dealdelete, setDealdelete] = useState(false);
  const [delete_deals_id, setDeleteDealsid] = useState();
  const handleClose1 = () => setDealdelete(false);
  const handleeditClose = () => setEdit(false);
  const getDeals = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "deals/alllist")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setDeals(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.localStorage.setItem("create", "none");
    // if (!deals.length)
    getDeals();
    var item_value = sessionStorage.getItem("email");
    if (!item_value) {
      setLogin(true);
    }
  }, []);

  const getCity = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "city/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCity(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {

    getCity();
  }, []);
  const handleChangename = (e) => {
    setDealname(e.target.value);
    console.log(e.target.value);
  };
  const handleChangephone = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const limitedInput = input.substring(0, 10);
    setPhone(limitedInput);
    console.log(limitedInput);
  };
  const handleChange = (e) => {
    setCityname(e.target.value);
    console.log(e.target.value);
  };
  const handleChangefile = (e) => {
    console.log(e.target.files);
    const file_detailsd = new FormData();
    file_detailsd.append("file", e.target.files[0]);
    file_detailsd.append("reference", "deals");

    axios
      .post(process.env.REACT_APP_API_URL + "file/upload", file_detailsd)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == "success") {
          // console.log(res);
          if (res.data.status === "success") {
            console.log("suceess", res.data.result.files[0].file_url);
            setFile(res.data.result.files[0].file_url);
            window.localStorage.setItem("create", "none");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleSelectChange = (event) => {
  //   const options = event.target.selectedOptions;
  //   const selectedValues = [];
  //   for (let i = 0; i < options.length; i++) {
  //     selectedValues.push(options[i].value);
  //   }
  //   console.log(selectedValues)
  //   setSelectedOptions(selectedValues);
  // };
  // const handleSelectChange = (event) => {
  //   const options = event.target.options;
  //   const value = [];
  //   if (options) {
  //     for (let i = 0; i < options.length; i++) {
  //       if (options[i].selected) {
  //         value.push(options[i].value);
  //       }
  //     }
  //     setSelectedOptions(value);
  //   }
  // }
  const handleSelectChange = (option) => {
    console.log("option", option);
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
    console.log("selectedOptions", selectedOptions);
  };
  const handlesubmit = (e) => {
    setValidated(true);
    console.log("tst");
    const value = [];
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        value.push(selectedOptions[i]);
      }
    }
    var user_id = sessionStorage.getItem("user_id");
    console.log("value", value);
    if (name && file_url && city_name) {
      console.log("city_name", city_name);
      const file_detailsd = new FormData();
      file_detailsd.append("name", name);
      file_detailsd.append("logo", file_url);
      file_detailsd.append("deals", "deliveries");
      file_detailsd.append(
        "description",
        "Deals are valid for a limited time only. Weedmaps reserves the right to modify or cancel deals at any time. The deal applies only to qualifying items displaying the deal offer on the item detail page. The offer will not be valid until it is applied to the qualifying item. The promotion is limited to one deal per customer. If you return any of the items ordered with a deal, the deal discount or value may be subtracted from the return credit. Offer good while supplies last. Void where prohibited. Weedmaps has no obligation for payment of any tax in conjunction with the distribution or use of any deal. Consumer is required to pay any applicable sales tax related to the use of the deal. Deals are void if restricted or prohibited by law."
      );
      file_detailsd.append(
        "account_details",
        "delBuy 1 @ regular price & Get 1"
      );
      file_detailsd.append("deliveries", "Golden Gate Cannabis Company");
      file_detailsd.append("country", "636cc724489d8c36e09a8fc6");
      file_detailsd.append("state", "63848078831a6f8c3562ac11");
      file_detailsd.append("city", city_name);
      file_detailsd.append("url", file_url);
      file_detailsd.append("expired_date", "2023-03-07T13:44:29.521+00:00");
      file_detailsd.append("phone", phone);
      file_detailsd.append("other_cities", value.join(", "));
      file_detailsd.append("user_id", user_id);

      axios
        .post(process.env.REACT_APP_API_URL + "deals/create", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setShow(false);
              getDeals();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const editDeals = (id) => {
    // setValidated(true);
    console.log("edit id", id);
    getDealsInfo(id);
    setDealsid(id);
    setEdit(true);
    console.log("formValues", formValues);
  };
  const deleteDealsConfirm = (id) => {
    console.log("delete id", id);
    if (id) {
      setDealdelete(true);
      setDeleteDealsid(id);
    }
  };
  const deleteDeals = () => {
    console.log("delete id", delete_deals_id);
    if (delete_deals_id) {
      const file_detailsd = new FormData();
      file_detailsd.append("deals", delete_deals_id);
      axios
        .post(process.env.REACT_APP_API_URL + "deals/delete", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setDealdelete(false);
              getDeals();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleLogin = (e) => {
    console.log("tst1");
    axios
      .get(process.env.REACT_APP_API_URL + "admin/user/info?first_name=" + email)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          if (
            email === res.data.result.first_name &&
            pwd === res.data.result.password
          ) {
            sessionStorage.setItem("email", res.data.result.email_address);
            sessionStorage.setItem("user_id", res.data.result._id);
            setLogin(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeemail = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
  };
  const handleChangepwd = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (event.target.name == "phone") {
      const input = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedInput = input.substring(0, 10);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const getDealsInfo = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "deals/list?deals_id=" + id)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setFormValues({
            name: res.data.result.name,
            phone: res.data.result.phone,
          });
          console.log("formValues 1", res.data.result.name);
          setFile(res.data.result.logo);
          setSelectedOptions(res.data.result.other_city);
          console.log("ts", res.data.result.other_city);
          // console.log("formValues 1", formValues)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleeditsubmit = (event) => {
    event.preventDefault();
    console.log("d", formValues);
    const value = [];
    if (selectedOptions) {
      for (let i = 0; i < selectedOptions.length; i++) {
        value.push(selectedOptions[i]);
      }
    }
    console.log("value12", value);
    if (formValues.name && file_url) {
      console.log("city_name", city_name);
      const file_detailsd = new FormData();
      file_detailsd.append("name", formValues.name);
      file_detailsd.append("logo", file_url);
      file_detailsd.append("deals", "deliveries");
      file_detailsd.append("url", file_url);
      file_detailsd.append("phone", formValues.phone);
      file_detailsd.append("deals_id", deals_id);
      file_detailsd.append("other_cities", value.join(", "));

      axios
        .post(process.env.REACT_APP_API_URL + "deals/update", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setEdit(false);
              getDeals();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="mrb-5">
      <Card style={{ width: "80rem" }}>
        <Card.Body>
          {/* <Card.Title>Deals</Card.Title> */}
          <Button
            variant="success"
            onClick={() => {
              console.log("fff");

              setShow(true);
            }}
          >
            ADD
          </Button>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Logo</th>
                <th>City</th>
                <th>Phone</th>
                <th>User</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {console.log("deals", deals)}
              {deals.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <img src={item.logo} alt="Logo" width="50" height="50" />
                    </td>
                    <td>{item.city.city_name}</td>
                    <td>{item.phone}</td>
                    <td>{item.user_id ? item.user_id.first_name : ""}</td>
                    <td>
                      <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editDeals(item._id);
                        }}
                      >
                        Edit
                      </Button>
                      <Button className="btn-sm mr-2"
                        variant="danger"
                        onClick={() => {
                          deleteDealsConfirm(item._id);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deals Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
              onChange={handleChangename}
            >
              <Form.Label>Deal Name</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>
            <Form.Group
              controlId="formFile"
              onChange={handleChangefile}
              className="mb-3"
            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" required />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState" className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select onChange={handleChange} defaultValue="Choose...">
                {city.map((item) => {
                  return <option value={item._id}> {item.city_name} </option>;
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="phone"
              onChange={handleChangephone}
            >
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Enter phone number"
                value={phone}
                required
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Please enter a valid 10-digit phone number.
            </Form.Control.Feedback>
            <Form.Group controlId="formMultiSelect" className="multiselmaxhgt">
              <Form.Label>Other cities</Form.Label>
              <Form.Control as="div">
                {city.map((item) => {
                  return (
                    <Form.Check
                      key={item._id}
                      type="checkbox"
                      id={item._id}
                      label={item.city_name}
                      value={item._id}
                      checked={selectedOptions.includes(item._id)}
                      // onChange={handleSelectChange}
                      onChange={() => handleSelectChange(item._id)}
                    />
                  );
                })}
              </Form.Control>
            </Form.Group>

            {/* <Form.Group controlId="formMultiSelect">
            <Form.Label> Other cities</Form.Label>
            <Form.Control as="select" multiple value={selectedOptions} onChange={handleSelectChange}>
              {city.map(item => {
                return (
                  <option value={item._id}> {item.city_name} </option>
                )
              })}
            </Form.Control>
          </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={edit} onHide={handleeditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Deals Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Deal Name</Form.Label>
              <Form.Control
                placeholder=""
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group
              controlId="logo"
              onChange={handleChangefile}
              value={file_url.split("/")[file_url.split("/").length - 1]}
              className="mb-3"
            >
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="logo"
                onChange={handleChangefile}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                name="phone"
                placeholder="Enter phone number"
                value={formValues.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Please enter a valid 10-digit phone number.
            </Form.Control.Feedback>
            <Form.Group controlId="formMultiSelect" className="multiselmaxhgt">
              <Form.Label>Other cities</Form.Label>
              <Form.Control as="div">
                {city.map((item) => {
                  return (
                    <Form.Check
                      key={item._id}
                      type="checkbox"
                      id={item._id}
                      label={item.city_name}
                      value={item._id}
                      checked={selectedOptions.includes(item._id)}
                      // onChange={handleSelectChange}
                      onChange={() => handleSelectChange(item._id)}
                    />
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleeditsubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={login} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="exampleForm.Controlemail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" onChange={handleChangeemail} required />
          </Form.Group> */}
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Username"
                name="name"
                onChange={handleChangeemail}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPlaintextPassword">
              <Form.Label column sm="2">
                Password
              </Form.Label>
              <Col>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={handleChangepwd}
                  required
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleLogin}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={dealdelete} onHide={handleClose1}>
        <Modal.Body>
          <Form>Are you sure you want to delete this deal?</Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteDeals}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Deals;
