import { useEffect } from "react";
import $ from "jquery";
const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const loadCSS = (url) => {
  // Create link
  let link = document.createElement("link");
  link.href = url;
  link.rel = "stylesheet";
  link.type = "text/css";

  let head = document.getElementsByTagName("head")[0];
  let links = head.getElementsByTagName("link");
  let style = head.getElementsByTagName("style")[0];

  // Check if the same style sheet has been loaded already.
  let isLoaded = false;
  for (var i = 0; i < links.length; i++) {
    var node = links[i];
    if (node.href.indexOf(link.href) > -1) {
      isLoaded = true;
    }
  }
  if (isLoaded) return;
  head.insertBefore(link, style);
};
export const removeCSS = (url) => {
  try {
    var querySelect = $(`link[href="${url}"]`).text();
    // $(`link[href="${url}"]`).text()
    if (querySelect) {
      querySelect.remove();
    }
  } catch (e) {
    console.log(e);
  }
};
export default useScript;
