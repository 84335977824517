/* eslint-disable array-callback-return */
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormControl } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
const Blog = () => {
  const [blog, setBlogs] = useState([]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setBlogdelete(false);
  const handleeditClose = () => setEdit(false);
  const [field, setField] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState(false);
  const [pwd, setPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const phonePattern = /^\d{10}$/;
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });
  const [edit, setEdit] = useState(false);
  const [blog_id, setblogid] = useState();
  const [blogdelete, setBlogdelete] = useState(false);
  const handleClosedelete = () => setBlogdelete(false);
  const [delete_blog_id, setDeleteBlogid] = useState();
  const [blogstatus, setStatus] = useState("");
  const [category, setCategory] = useState("");

  var modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ align: [] }, "direction"],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "super" }, { script: "sub" }],
      ["blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const handleFileUpload = (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      console.log("file123", file);
      // Replace 'upload-url' with your backend endpoint for handling file uploads
      const response = axios.post("upload-url", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const imageUrl = response.data.imageUrl;

      // Insert the image into the editor
      const quill = document.querySelector(".ql-editor");
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", imageUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  // const modules = {
  //   toolbar: {
  //     container1: [
  //       [{ font: [] }, { size: [] }],
  //       ["bold", "italic", "underline", "strike"],
  //       [{ color: [] }, { background: [] }],
  //       [{ script: "super" }, { script: "sub" }],
  //       ["blockquote", "code-block"],
  //       [{ list: "ordered" }, { list: "bullet" }],
  //       ["link", "image", "video"],
  //       ["clean"],
  //     ],
  //     handlers: {
  //       image: () => {
  //         const fileEditor = document.createElement("input");
  //         fileEditor.setAttribute("type", "file");
  //         fileEditor.setAttribute("accept", "image/*");
  //         fileEditor.click();

  //         fileEditor.onchange = async () => {
  //           const file = fileEditor.files[0];
  //           handleFileUpload(file);
  //         };
  //       },
  //     },
  //   },
  // };
  const getBlogs = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "blog/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setBlogs(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.localStorage.setItem("create", "none");
    // if (!blog.length)
    getBlogs();
    var item_value = sessionStorage.getItem("email");
    if (!item_value) {
      setLogin(true);
    }
  }, []);

  const handleChangetitle = (e) => {
    setTitle(e.target.value);
    console.log(e.target.value);
  };
  const ReactHtml = (data) => {
    const htmlContent = data;

    const divElement = document.createElement("div");
    divElement.innerHTML = htmlContent;
    const textContent = divElement.innerText;

    return (
      <div>
        {textContent.length > 110
          ? textContent.substring(0, 110) + "..."
          : textContent}
      </div>
    );
  };
  // const handleChangeDescription = (e) => {
  const handleChangeDescription = (value) => {
    setDescription(value);
    console.log(value);
  };
  const handleChangestatus = (e) => {
    setStatus(e.target.value);
    console.log(e.target.value);
  };
  const handleChangecategory = (e) => {
    setCategory(e.target.value);
    console.log(e.target.value);
  };

  const handlesubmit = (e) => {
    setValidated(true);
    var user_id = sessionStorage.getItem("user_id");
    if (title && description) {
      const file_detailsd = new FormData();
      file_detailsd.append("title", title);
      file_detailsd.append("description", description);
      file_detailsd.append("user_id", user_id);
      file_detailsd.append("blog_status", blogstatus);
      file_detailsd.append("category", category);

      axios
        .post(process.env.REACT_APP_API_URL + "blog/create", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setShow(false);
              getBlogs();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const editBlog = (id) => {
    // setValidated(true);
    console.log("edit id", id);
    getBlogsInfo(id);
    setblogid(id);
    setEdit(true);
    console.log("formValues", formValues);
  };

  const deleteBlogConfirm = (id) => {
    console.log("delete id", id);
    if (id) {
      setBlogdelete(true);
      setDeleteBlogid(id);
    }
  };
  const deleteBlog = () => {
    console.log("delete id", delete_blog_id);
    if (delete_blog_id) {
      const file_detailsd = new FormData();
      file_detailsd.append("blog", delete_blog_id);
      axios
        .post(process.env.REACT_APP_API_URL + "blog/delete", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setBlogdelete(false);
              getBlogs();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleLogin = (e) => {
    console.log("tst1");
    axios
      .get(
        process.env.REACT_APP_API_URL + "admin/user/info?first_name=" + email
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          if (
            email === res.data.result.first_name &&
            pwd === res.data.result.password
          ) {
            sessionStorage.setItem("email", res.data.result.email_address);
            sessionStorage.setItem("user_id", res.data.result._id);
            setLogin(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeemail = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
  };
  const handleChangepwd = (e) => {
    setPassword(e.target.value);
    console.log(e.target.value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const getBlogsInfo = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "blog/list?blog_id=" + id)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setFormValues({
            title: res.data.result.title,
            description: res.data.result.description,
          });
          setDescription(res.data.result.description);
          console.log("formValues 1", formValues);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleeditsubmit = (event) => {
    event.preventDefault();
    console.log("d", formValues);
    console.log("d", formValues);
    var user_id = sessionStorage.getItem("user_id");
    if (formValues.title && description) {
      const file_detailsd = new FormData();
      file_detailsd.append("title", formValues.title);
      file_detailsd.append("description", description);
      file_detailsd.append("user_id", user_id);
      file_detailsd.append("blog_id", blog_id);

      axios
        .post(process.env.REACT_APP_API_URL + "blog/update", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setEdit(false);
              getBlogs();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // Do something with the form data
  };
  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];

  //   // Perform any necessary logic with the uploaded file here
  //   if (file) {
  //     console.log("Uploaded file:", file);
  //     // You can use APIs like Fetch or Axios to send the file to the server
  //   }
  // };
  return (
    <div className="mrb-5">
      <Card style={{ width: "80rem" }}>
        <Card.Body>
          {/* <Card.Title>blog</Card.Title> */}
          <Button
            variant="success"
            onClick={() => {
              console.log("fff");
              setDescription("");
              setShow(true);
            }}
          >
            ADD
          </Button>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {console.log("blog", blog)}
              {blog.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.title}</td>
                    <td
                      style={{
                        "white-space": "normal",
                        "word-break": " break-all",
                      }}
                    >
                      {ReactHtml(item.description)}
                    </td>

                    <td>
                      <Button
                        className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editBlog(item._id);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn-sm mr-2"
                        variant="danger"
                        onClick={() => {
                          deleteBlogConfirm(item._id);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Blog Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
              onChange={handleChangetitle}
            >
              <Form.Label>Title</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <ReactQuill
                className="your-component"
                id="yourcomponent"
                // className="ql-editor"
                value={description}
                modules={modules}
                onChange={handleChangeDescription}
              />
            </Form.Group>

            <Form.Group controlId="formGridState" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                onChange={handleChangestatus}
                defaultValue="Choose..."
              >
                <option value="">Choose...</option>
                {["published", "draft", "pending"].map((item) => {
                  return <option value={item}> {item} </option>;
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGridState" className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Select
                onChange={handleChangecategory}
                defaultValue="Choose..."
              >
                <option value="">Choose...</option>
                {["category1", "category2"].map((item) => {
                  return <option value={item}> {item} </option>;
                })}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={edit} onHide={handleeditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Blog Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Deal Name</Form.Label>
              <Form.Control
                placeholder=""
                name="title"
                value={formValues.title}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder=""
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <ReactQuill
                value={description}
                onChange={handleChangeDescription}
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="description1">
              <Form.Label>Description</Form.Label>
              <ReactQuill
                className="your-component"
                // className="ql-editor"
                value={description}
                modules={modules}
                onChange={handleChangeDescription}
              />
            </Form.Group>

            <Form.Group controlId="formGridState" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                onChange={handleChangestatus}
                defaultValue="Choose..."
              >
                <option value="">Choose...</option>
                {["published", "draft", "pending"].map((item) => {
                  return <option value={item}> {item} </option>;
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formGridState" className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Select
                onChange={handleChangecategory}
                defaultValue="Choose..."
              >
                <option value="">Choose...</option>
                {["category1", "category2"].map((item) => {
                  return <option value={item}> {item} </option>;
                })}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleeditsubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={login} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="exampleForm.Controlemail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" onChange={handleChangeemail} required />
          </Form.Group> */}
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Username</Form.Label>
              <Form.Control
                placeholder="Username"
                name="name"
                onChange={handleChangeemail}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPlaintextPassword">
              <Form.Label column sm="2">
                Password
              </Form.Label>
              <Col>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={handleChangepwd}
                  required
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleLogin}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={blogdelete} onHide={handleClosedelete}>
        <Modal.Body>
          <Form>Are you sure you want to delete this Blog?</Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteBlog}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Blog;
