/* eslint-disable array-callback-return */
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FormControl } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import TimestampDisplay from "./date";
const Coupon = () => {
  const [couponlist, setCouponList] = useState([]);
  const [show, setShow] = useState(false);
  const [percent, setPercent] = useState("");
  const [promotion_code, setPromotionCode] = useState("");





  const handleClose = () => setShow(false);
  const [field, setField] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState(false);
  const [pwd, setPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const phonePattern = /^\d{10}$/;
  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
  });
  const [edit, setEdit] = useState(false);
  const [Coupon_id, setCouponid] = useState("");
  const [productdelete, setProductdelete] = useState(false);
  const [coupon_status, setCouponStatus] = useState(false);
  const [delete_Coupon_id, setDeleteCouponid] = useState();
  const handleClose1 = () => setProductdelete(false);
  const handleeditClose = () => setEdit(false);
  const getCoupon = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "admin/coupon/list")
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          console.log("suceess");
          setCouponList(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.localStorage.setItem("create", "none");
    // if (!Coupon.length)
    getCoupon();
    var item_value = sessionStorage.getItem("email");
    if (!item_value) {
      setLogin(true);
    }
  }, []);

  const handlePercent = (e) => {
    setPercent(e.target.value);
    console.log(e.target.value);
  };
  const handlePromotionCode = (e) => {
    setPromotionCode(e.target.value);
    console.log(e.target.value);
  };
  const handlesubmit = (e) => {

    var user_id = sessionStorage.getItem("user_id");
    if (percent && promotion_code) {
      const file_detailsd = new FormData();
      file_detailsd.append("promotion_code", promotion_code);
      file_detailsd.append("percent", percent);
      file_detailsd.append("user_id", user_id);

      axios
        .post(process.env.REACT_APP_API_URL + "Coupon/create", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1"); toast.success(res.data.message);
              setShow(false);
              getCoupon();

              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // const editCoupon = (id) => {
  //   // setValidated(true);
  //   console.log("edit id", id);
  //   getCouponInfo(id);
  //   setCouponid(id);
  //   setEdit(true);
  //   console.log("formValues", formValues);
  // };
  const deleteCouponConfirm = (id, coupon_status) => {
    console.log("delete id", id);
    if (id) {
      setCouponStatus(coupon_status);
      setProductdelete(true);
      setDeleteCouponid(id, coupon_status);
    }
  };
  const deleteCoupon = () => {
    console.log("delete id", delete_Coupon_id);
    if (delete_Coupon_id) {
      const file_detailsd = new FormData();
      file_detailsd.append("coupon_id", delete_Coupon_id);
      file_detailsd.append("coupon_status", coupon_status);
      axios
        .post(process.env.REACT_APP_API_URL + "coupon/delete", file_detailsd)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == "success") {
            console.log(res);
            if (res.data.status === "success") {
              console.log("suceess1");
              setProductdelete(false);
              getCoupon();
              // setFile(res.data.result.files[0].file_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };



  return (
    <div className="mrb-5">
      <Card style={{ width: "80rem" }}>
        <Card.Body>
          {/* <Card.Title>Coupon</Card.Title> */}
          <Button
            variant="success"
            onClick={() => {
              console.log("fff");

              setShow(true);
            }}
          >
            ADD
          </Button>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Promotion code</th>
                <th>Percent</th>
                <th>Expired date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {console.log("Coupon", Coupon)}
              {couponlist && couponlist.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.promotion_code}</td>
                    <td>{item.percent}%</td>
                    <td><TimestampDisplay isoTimestamp={item.expired_date} /></td>
                    <td>
                      {/* <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          editCoupon(item._id);
                        }}
                      >
                        Edit
                      </Button> */}
                      <Button className="btn-sm mr-2"
                        variant="primary"
                        onClick={() => {
                          deleteCouponConfirm(item._id, item.status === 'inactive' ? "active" : "inactive");
                        }}
                      >
                        {item.status == 'active' ? 'Disable' : 'Enable'}
                      </Button>
                      <Button className="btn-sm mr-2"
                        variant="danger"
                        onClick={() => {
                          deleteCouponConfirm(item._id, "deleted");
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated}>
            <Form.Group
              className="mb-3"
              controlId="formGridAddress1"
              onChange={handlePromotionCode}
            >
              <Form.Label>Promotion Code</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>


            <Form.Group
              className="mb-3"
              controlId="price"
              onChange={handlePercent}
            >
              <Form.Label>Percent</Form.Label>
              <Form.Control placeholder="" required />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={edit} onHide={handleeditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Deal Name</Form.Label>
              <Form.Control
                placeholder=""
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group
              controlId="logo"
              onChange={handleChangefile}
              value={file_url.split("/")[file_url.split("/").length - 1]}
              className="mb-3"
            >
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="logo"
                onChange={handleChangefile}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                name="phone"
                placeholder="Enter phone number"
                value={formValues.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Please enter a valid 10-digit phone number.
            </Form.Control.Feedback>
            <Form.Group controlId="formMultiSelect" className="multiselmaxhgt">
              <Form.Label>Other cities</Form.Label>
              <Form.Control as="div">
                {city.map((item) => {
                  return (
                    <Form.Check
                      key={item._id}
                      type="checkbox"
                      id={item._id}
                      label={item.city_name}
                      value={item._id}
                      checked={selectedOptions.includes(item._id)}
                      // onChange={handleSelectChange}
                      onChange={() => handleSelectChange(item._id)}
                    />
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleeditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleeditsubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={productdelete} onHide={handleClose1}>
        <Modal.Body>
          <Form>Are you sure you want to {coupon_status === 'deleted' ? 'delete' : coupon_status} this coupon?</Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteCoupon}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Coupon;
